import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { ProtectedRoute, GuestRoute } from "./ProtectedRoutes";
import Login from "./pages/login/Login";
import MainContainer from "./pages/MainContainer";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login}></Route>
        <Route path="/" component={MainContainer}></Route>
      </Switch>
    </Router>
  );
};

export default Routes;
