import dashboard from "../assets/icons/dashboard.png";
import transactions from "../assets/icons/transactions.png";
import helpCircle from "../assets/icons/helpCircle.png";
import masternodes from "../assets/icons/masternodes.png";
import dex from "../assets/icons/dex.png";
import facebook from "../assets/icons/facebook.png";
import instagram from "../assets/icons/instagram.png";
import linkdin from "../assets/icons/linkdin.png";
import twitter from "../assets/icons/twitter.png";
import logout from "../assets/icons/logout.png";
import selectedDashboard from "../assets/icons/selectedDashboard.png";
import selectedTransactions from "../assets/icons/selectedTransactions.png";
import selectedHelp from "../assets/icons/selectedHelp.png";
import selectedMasternodes from "../assets/icons/selectedMasternodes.png";
import selectedDex from "../assets/icons/selectedDex.png";
import selectedLogout from "../assets/icons/selectedLogout.png";
const config = require("prcyjs").Constants;

export const current_block_api = config.PRCYCHAIN === 'testnet' ? "https://testnetwallet.prcycoin.com/api/blockcount" : "https://api.prcycoin.com/api/blockcount";
export const sidebarMenuItems = [
  {
    name: "Dashboard",
    path: "",
    src: dashboard,
  },
  {
    name: "Transactions",
    path: "transactions",
    src: transactions,
  },
  {
    name: "Help",
    path: "help",
    src: helpCircle,
  },
  {
    name: "Masternodes",
    path: "masternodes",
    src: masternodes,
  },
  {
    name: "PRivaCY Dex",
    path: "dex",
    src: dex,
  },
];


export const footerIcons = [
  {
    name: "Dashboard",
    path: "",
    src: dashboard,
    selectedSrc: selectedDashboard
  },
  {
    name: "Transactions",
    path: "transactions",
    src: transactions,
    selectedSrc: selectedTransactions
  },
  {
    name: "Help",
    path: "help",
    src: helpCircle,
    selectedSrc: selectedHelp
  },
  {
    name: "Masternodes",
    path: "masternodes",
    src: masternodes,
    selectedSrc: selectedMasternodes
  },
  {
    name: "PRivaCY Dex",
    path: "dex",
    src: dex,
    selectedSrc: selectedDex
  },
  {
    name: "Logout",
    path: "login",
    src: logout,
    selectedSrc: selectedLogout
  },
];
export const infoMessage =
  "If you looking for some help, you can go to our support center and talk with your personal manager";
export const successMessage =
  "You have successfully reset your password. Now you can easily sign in to your account";
export const errorMessage =
  "Unfortunately something went wrong. We are doing our best right now to fix the problem";
export const specsAndInfoPara1 =
  "PRCY is a hybrid privacy blockchain, which provides enhanced security, scalability along with total privacy. The PRCY protocol creates a fully anonymous staking coin with a Trustless governance structure. PRCY is a non-ICO community-driven project that started Q1 of 2021.";
export const specsAndInfoPara2 =
  "\n ";
export const shamefullMomentQuestion = "PRCY Chain Features";
export const shamefullMomentPoints = [
  "Proof of Audit - A unique on-chain solution to the trust problem of private crypto networks.",
  "RingCT - The world’s leading privacy protocol.",
  "Bulletproofs - Short non-interactive zero-knowledge proofs that require no trusted setup.",
  "PoSv3 – Fair and more energy-efficient version of Proof of Stake.",
  "Masternodes – use 24/7 online collateralized nodes to augment chain and work as service nodes.",
  "Stealth Addresses – Mandatory stealth addresses."
];
export const prcyCoinSpecsPoints = [
  "Block verification: PoA/PoS/MN \n Proof-Of-Audit algo: Unique \n Proof-of-Stake algo: PoSV3",
  "Block time: 1 minute \n Block reward: 1 PRCY",
  "EFFECTIVE BLOCK REWARD: 1 PRCY \n BLOCK REWARD SPLIT: 1 MN/PoS, 32.5 reserved for PoA miners",
  "SEE-SAW MN/PoS REWARD: 60/40 MN/PoS reward split, re-balance up to maximum of 40/60 MN/PoS \n PRCY INITIAL COIN SUPPLY: Maximum 60,000,000 \n BLOCK EMISSIONS LIMIT: 10M",
  "Years of emissions: ~16 years \n Approximate annual inflation: 4-12% [Staking; factor of difficulty+luck], or ~400k PRCY per year until 10m emissions cap is reached.",
  "MINIMUM STAKING AMOUNT: 2,500 PRCY \n MASTERNODE COLLATERAL: 5,000 PRCY \n CONFIRMS TO SPEND: 4 blocks \n CONFIRMS TO STAKE: 100 blocks"
];
export const systemContent =
  "PRCY is a staking and masternode privacy cryptocurrency implementation RingCT, Ring signatures, and Bulletproofs – we made some significant breakthroughs concerning the ‘trust issue’ and reaching the ideal iteration of an anonymous blockchain network. \n The Proof-Of-Audit idea and PRCY Protocol implementation is called the HARPOCRATES Protocol and is a new industry standard. \n We achieve complete obfuscation of all users and transactions. This mix of features, featuring Proof-of-Audit creates a completely trustless anonymous blockchain network.";
export const summaryPoints = [
  "Private and secure when transacting with PRCY.",
  "Earn passive income with staking, mining and masternodes.",
  "One of the most engaging and active communities in crypto.",
  "24/7 Support available via our Telegram support channels.",
];
export const socialMediaIcons = [facebook, twitter, instagram, linkdin];
export const rightbarSubList = [
  "Getting started",
  "Managing",
  "Sending and receiving",
  "Other Topics",
];
export const warningMessage = [
  "To create a new wallet your Mnemonic Recovery Phrase must be displayed on screen. We strongly recommend doing so when you can safely copy/write down the Phrase as we do not store it.",
];
export const amountRegex = /^[1-9]\d*(\.\d+)?$/;
export const addressRegex = /^Pa[0-9][A-Z][a-z]{9}$/;
export const required = "field is required";


export const faqs = [
  {
    ques: "What does the Login button do?",
    ans: "The Login button takes your 24 word seed phrase and locally, on your computer, decrypts yours private and public spend and view keys."
  },
  {
    ques: "I have lost my 24 word seed phrase, what now?",
    ans: "If you were using your desktop wallet 24 word seed phrase, then just go back to the desktop wallet and get it again. If you created a new Web Wallet and have lost that phrase then there is no way to recover it. This is because we do not store any user data - for your safety - and as such we cannot get that 24 word seed phrase back."
  },
  {
    ques: "What does Create Wallet do?",
    ans: "Creating a wallet generates a new 24 word seed phrase. \n This is a newly created wallet that never existed on the network before."
  },
  {
    ques: "My PRCY is not showing?",
    ans: "This is possibly due to the scanning of your wallet still taking place. Please try again later."
  },
  {
    ques: "Why is scanning taking so long?",
    ans: "The PRCY Web Wallet has to scan the whole PRCY Blockchain to find all of the transactions (UTXOs) linked to your seed / wallet address and a full privacy chain like PRCY takes a little longer to process these than other traditional chains. Thank you for being patient!"
  },
  {
    ques: "Can I stake with the Web Wallet?",
    ans: "Due to the nature of POSV3 staking on the PRCY chain, web wallets cannot stake as they cannot contribute to the PRCY network, therefore cannot receive rewards. Staking is only available on the full node desktop wallet or through a staking pool provider."
  },
  {
    ques: "Can I set up Masternodes with the Web Wallet?",
    ans: "Due to the nature of Masternodes, you cannot run a masternode from the web wallet. This is only available using the full node desktop wallet or a Masternode provider."
  },
  {
    ques: "What are the benefits of using the PRCY web wallet over a full node desktop wallet?",
    ans: "- You can save considerable time, the PRCY web wallet does not need to sync the full PRCY blockchain unlike a desktop wallet. \n - Access your funds anywhere on PC / Mac / Linux and on your mobile device browser! \n - Web Wallets do not have system requirements like full nodes do. As long as you have a web browser, you can use them."
  },
  {
    ques: "What does the 'Do not spend Masternode Collateral UTXOs' option mean?",
    ans: "If you are a Masternode owner, using this option will avoid spending any UTXOs that are exactly 5000 PRCY. If not selected, Masternodes can potentially be broken if it's collateral transaction is selected to be spent."
  },
  {
    ques: "Rewards:",
    ans: "Masternode and staking rewards will show in the Web Wallet even though it does not do either of these right now, it still can see the incoming rewards."
  },
  {
    ques: "Support and Bugs:",
    ans: "Contact us @prcySupport on Telegram. \n We never DM users first on Telegram and never ask for sensitive information."
  }
]
