import React, { useContext, useState, useEffect } from "react";
import gridView from "../../assets/icons/gridView.png";
import cardView from "../../assets/icons/cardView.png";
import Transaction from "../../components/transaction/Transaction";
import GridView from "../../components/grid/GridView";
import Context from "../../store/Store";
import cloneDeep from 'lodash/cloneDeep';
import { withRouter } from 'react-router-dom'
import "./Transactions.css";

const Transactions = (props) => {
  const [layoutType, setLayoutType] = useState(false);
  const [state] = useContext(Context)
  const [data, setData] = useState([]);

  const onClickLayoutBtn = (type) => {
    setLayoutType(type);
  };

  useEffect(() => {
    if(state.wallet.transactionHistory) {
      const tempData = cloneDeep(Object.values(state.wallet.transactionHistory))
      tempData.sort(function(x, y){
        return y.timestamp - x.timestamp;
      })
      setData(tempData)
    } else {
      props.history.push("/login");
    }
  }, [props.history, state.wallet.transactionHistory]);
 
  return (
    <div className="transactions-container">
      <div className="card-layout-icons">
        <div className="transactionHeading">Transactions</div>
        <div className="transactionsIconsDiv">
          <div
            className={!layoutType ? "selecetdLayoutIconDiv" : "layoutIconDiv"}
          >
            <img
              className="layoutIcon"
              src={cardView}
              alt="img"
              onClick={() => onClickLayoutBtn(false)}
            />
          </div>
          <div
            className={layoutType ? "selecetdLayoutIconDiv" : "layoutIconDiv"}
          >
            <img
              className="layoutIcon"
              src={gridView}
              alt="img"
              onClick={() => onClickLayoutBtn(true)}
            />
          </div>
        </div>
      </div>
      {layoutType ? (
        <div className="table-layout">
          <GridView />
        </div>
      ) : (
        <div className="card-layout">
          {data && data.map((transaction) => {
            return (
              <Transaction
                key={transaction.txid}
                txId={transaction.txid}
                type={transaction.type}
                amount={transaction.amount}
                timestamp={transaction.timestamp}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default withRouter(Transactions);
