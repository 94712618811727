import React, { useContext, useState } from "react";
import Context from "../../store/Store";
import Transaction from "../../components/transaction/Transaction";
import help from "../../assets/icons/helpCircle.png";
import rightArrow from "../../assets/icons/rightArrow.png";
import lastPay from "../../assets/icons/lastPay.png";
import battery from "../../assets/icons/battery.png";
import sendArrow from "../../assets/icons/sendArrow.png";
//import Rectangle from "../../assets/images/Rectangle.png";
import appsIcon from "../../assets/icons/appsIcon.png";
import googlePlay from "../../assets/images/googlePlay.png";
import appStore from "../../assets/images/appStore.png";
import Background from "../../assets/images/background.png";
import Header from "../../components/header/Header";
import Loader from "../../components/loader/Loader";
import spendable from "../../assets/icons/spendable.png";
import pending from "../../assets/icons/pending.png";
import total from "../../assets/icons/total.png";
import getToKnow from "../../assets/icons/getToKnow.png";
import QRCode from "../../assets/icons/QRCode.png";
import bcount from "../../assets/icons/bcount.png";
import immature from "../../assets/icons/immature.png";
import { amountRegex, required } from "../../constants/index";
import "./Dashboard.css";
import Alert from "../../components/alerts/Alert";
import warningAlert from "../../assets/icons/warningAlert.png";
import successAlert from "../../assets/icons/successAlert.png";
import errorAlert from "../../assets/icons/errorAlert.png";
import { withRouter } from "react-router-dom";
const PrcyUtils = require("prcyjs").Utils;
const config = require("prcyjs").Constants;

const Dashboard = React.memo((props) => {
  const [initialState, dispatch] = useContext(Context);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [warning, setWarning] = useState(false);
  const [checked, setChecked] = useState(false);
  const [state, setState] = useState({
    address: "",
    amount: "",
    errors: {
      address: "",
      amount: "",
    },
  });
  
  const estimatedFee = initialState.wallet.estimatedFee ? initialState.wallet.estimatedFee() : 0
  const lastPayment = initialState.wallet.lastPayment ? Math.round(initialState.wallet.lastPayment() * 1000) / 1000 : "0";
  const lastChange = initialState.prcyChange ? initialState.prcyChange.toFixed(4) : "0";
  
  const warningClose = () => {
    setWarning(false);
  };

  const onClickGotItBtn = () => {
    submit();
    setWarning(false);
  };

  const onClickSendBtn = () => {
    validate("address", state.address);
    validate("amount", state.amount);
    let address, amount;
    if (state.address === "" || state.amount === "") {
      if (state.address === "") {
        address = `Address ${required}`;
      }
      if (state.amount === "") {
        amount = `Amount ${required}`;
      }
      setState({
        ...state,
        errors: {
          address,
          amount,
        },
      });
    } else if (state.errors.address === "" && state.errors.amount === "") {
      setWarning(true);
    }
  };

  const redirectToHelp = () => {
    props.history.push("/help");
  };

  const modalClose = () => {
    clearAll();
    setShowModal(false);
    dispatch({
      type: "EMPTY_OBJECT",
    });
  };

  const setInputField = (evt) => {
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });
    validate(evt.target.name, evt.target.value);
  };

  const viewAllTransactions = () => {
    props.history.push("/transactions");
  };

  const clearAll = () => {
    setState({
      address: "",
      amount: "",
      errors: {
        address: "",
        amount: "",
      },
    });
    setChecked(false);
    config.LOCK_COLLATERAL = false;
  };

  const validate = (name, value) => {
    switch (name) {
      case "address":
        state.errors.address =
          value === ""
            ? `Address ${required}`
            : (value.startsWith('Pa') && value.length === 99) ||
              (value.startsWith('Pk') && value.length === 110)
            ? ""
            : "Please enter valid address.";
        break;
      case "amount":
        state.errors.amount =
          value === ""
            ? `Amount ${required}`
            : !amountRegex.test(value)
            ? "Please enter valid amount."
            : parseFloat(value) + 0.1 >
              PrcyUtils.balanceInPRCY(initialState.wallet.spendable)
            ? "You do not have sufficient funds for this transaction. Please subtract the fee or wait until you have a Spendable balance."
            : "";
        break;
      default:
        break;
    }
  };

  const submit = () => {
    setLoader(true);
    initialState.wallet.sendTo(state.address, state.amount, function (ret) {
      if (ret.success) {
        initialState.wallet.recomputeBalance();
        setLoader(false);
        dispatch({
          type: "SEND_TO_ADDRESS",
          payload: {
            success: true,
            message:
              "Successfully created transaction.\n\n" +
              state.amount +
              " PRCY sent to " +
              state.address +
              "\n\nTXID: " +
              ret.txid,
          },
        });
      } else {
        setLoader(false);
        dispatch({
          type: "SEND_TO_ADDRESS",
          payload: { success: false, message: "Error: " + ret.reason },
        });
      }
    });
  };

  const balanceItems = [
    {
      name: "Spendable",
      src: spendable,
      balance: initialState.wallet.spendable
        ? Math.round(PrcyUtils.balanceInPRCY(initialState.wallet.spendable) * 1000) / 1000
        : 0,
      unit: "PRCY",
    },
    {
      name: "Pending",
      src: pending,
      balance: initialState.wallet.pending
        ? Math.round(PrcyUtils.balanceInPRCY(initialState.wallet.pending) * 1000) / 1000
        : 0.0,
      unit: "PRCY",
    },
    {
      name: "Immature",
      src: immature,
      balance: initialState.wallet.immature
        ? Math.round(PrcyUtils.balanceInPRCY(initialState.wallet.immature) * 1000 ) / 1000
        : 0,
      unit: "PRCY",
    },
    {
      name: "Total (USD)",
      src: total,
      balance: initialState.wallet.spendable
        ? Math.round(
            PrcyUtils.balanceInPRCY(
              initialState.wallet.spendable + initialState.wallet.immature
            ) * initialState.prcyPrice * 1000
          ) / 1000
        : 0,
      unit: "$",
    },
    {
      name: "Current Block",
      src: bcount,
      balance: initialState.wallet.currentBlockHeight
        ? initialState.wallet.currentBlockHeight
        : 0,
      unit: "",
    }
    
  ];

  const onClickGenerateQR = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: true,
    });
  };

  const goToAndroid = () => {
    window.open('https://play.google.com/store/apps/details?id=com.wallet.prcy', '_blank');
  };

  const goToiOS = () => {
    window.open('https://apps.apple.com/app/privacy-coin-web-wallet/id1637664062', '_blank');
  };

  const onClick24Change = () => {
    window.open('https://www.coingecko.com/en/coins/prcy-coin', '_blank');
  };

  const handleChange = () => {
    setChecked(!checked);
    config.LOCK_COLLATERAL = !checked;
  };
  
  return (
    <>
      {props.loader || loader ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <>
          <div className="dashboard-container">
            <div className="content">
              <Header />

              { props.showBalance ?
                <div
                className={
                  !initialState.mobileToggle && props.width <= 480
                    ? "p-10 mobileMode"
                    : "p-10"
                }
              >
                <div className="balance">
                  {props.width <= 480 ? null: <div className="balance-heading">Balance</div>}
                  <div className="balance-body">
                  {props.width <= 480 ? null: <div className="balance-body-total">Total</div>}
                    <div>
                      <span className="balance-body-amount">
                      {initialState.wallet.spendable
                        ? Math.round(PrcyUtils.balanceInPRCY(initialState.wallet.spendable) * 1000) / 1000
                        : 0}
                    </span>
                    <span className="balance-body-unit"> PRCY</span>
                    </div>
                    {props.width <= 480 ? <div className="balanceQRGenerate" onClick={onClickGenerateQR}> 
                      <img className="balanceQRGenerateIcon" src={QRCode} alt="alt" />
                    </div>: null}
                  </div>
                  <div>
                    {balanceItems.map((items) => {
                      return (
                        <>
                          <div className="balance-info">
                            <div className="spendable">
                              <div className="spendable-type">
                                <span className="spendable-icon">
                                  <img
                                    className="balanceIcons"
                                    src={items.src}
                                    alt="img"
                                    style={{ "width" : items.src === bcount ? "19px" : "16px"}}
                                  />
                                </span>
                                <span style={{marginRight: "5px"}}>{items.name}</span>
                              </div>
                              <div>
                                <div>
                                  {items.balance}
                                  <span className="spendable-type">
                                    {` ${items.unit}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                  </div>  : null}
              <div
                className={
                  !initialState.mobileToggle && props.width <= 480
                    ? "p-10 mobileMode"
                    : "p-10"
                }
              >
                <div className="send">
                  <div className="sendBtnsDiv">
                    <div className="balance-heading">Send</div>
                    {/* <div className="btnsDiv">
                    <button
                      className={
                        send ? "sendAndReceiveBtn" : "sendAndReceiveBtnInActive"
                      }
                      onClick={() => onClickSendBtn(true)}
                    >
                      Send
                    </button>
                    <button
                      className={
                        !send ? "sendAndReceiveBtn" : "sendAndReceiveBtnInActive"
                      }
                      onClick={() => onClickSendBtn(false)}
                    >
                      Receive
                    </button>
                  </div> */}
                  </div>
                  <div
                    className={`codeInputFieldDiv ${
                      state.errors.address !== "" ? "error-border" : null
                    }`}
                  >
                    <input
                      className="codeInputField"
                      type="text"
                      placeholder="PRCY Address"
                      name="address"
                      value={state.address}
                      onBlur={setInputField}
                      onChange={setInputField}
                    />
                    
                  </div>
                  <div className="errors">{state.errors.address}</div>
                  <div
                    className={`codeInputFieldDiv ${
                      state.errors.amount !== "" ? "error-border" : null
                    }`}
                  >
                    <input
                      className="codeInputField"
                      type="number"
                      placeholder="PRCY Amount"
                      name="amount"
                      onChange={setInputField}
                      value={state.amount}
                    />
                  </div>
                  <div className="errors">{state.errors.amount}</div>
                    <div className="bitCoinCheckDiv">
                      <Checkbox
                        name="collateral"
                        value={checked}
                        onChange={handleChange}
                      />
                      <p className="bitCoinCheckMessage">{props.width <= 480 ? "Do not spend Masternode Collateral" : "Do not spend Masternode Collateral UTXOs"}
                      <button className="buttonLink" onClick={redirectToHelp}>?</button></p>
                    </div>
                  <div className="clearAllBtnsDiv">
                    <div>
                      <button className="clearAllBtn" onClick={clearAll}>
                        Clear all
                      </button>
                    </div>
                    <div>
                      <button className="sendBtn" onClick={onClickSendBtn}>
                        Send
                        <img
                          className="sendArrowIcon"
                          src={sendArrow}
                          alt="img"
                        />
                      </button>
                      {/* {send ? (
                      <button className="sendBtn">
                        Send
                        <img
                          className="sendArrowIcon"
                          src={sendArrow}
                          alt="img"
                        />
                      </button>
                    ) : (
                      <button className="requestBtn">Request</button>
                    )} */}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div
                className={
                  initialState.mobileToggle && props.width <= 480
                    ? "p-10 mobileMode"
                    : "p-10"
                }
              >
                <div className="help">
                  <div className="helpp">
                    <div className="helpDiv">
                      <label className="helpTitle">HELP</label>
                      <div>
                        <img className="helpIcon" src={help} alt="img" />
                      </div>
                    </div>
                    <div className="helpDiv">
                      <div className="whyDelay">
                        My PRCY is <br />
                        not showing?
                      </div>
                      <div className="getKnowBtnDiv">
                        {props.width <= 480 ? <button className="getKnowBtn" onClick={redirectToHelp}>
                          <img className="getKnowBtnIcon" src={getToKnow} alt="img"/>
                        </button> : <button className="getKnowBtn" onClick={redirectToHelp}>
                          FAQ
                        </button>}
                      </div>
                    </div>
                  </div>
                  <div className="growth">
                    <div className="growthDiv">
                        <div className="lastChangeTitle">24H PRICE CHANGE</div>
                      <div>
                        <img className="batteryIcon" src={battery} alt="alt" />
                      </div>
                    </div>
                      <div className="lastChangeAmount" onClick={onClick24Change}>{isNaN(lastChange) ? "0" : lastChange}%</div>
                      <span className="prcy"></span>
                  </div>
                  <div className="lastPay">
                    <div className="growthDiv">
                      <div>
                        <img className="lastPayIcon" src={lastPay} alt="alt" />
                      </div>
                      <div>
                        <label className="lastPayTitle">LAST RECEIVED</label>
                      </div>
                    </div>
                    <div className="lastPayAmount">
                      { isNaN(lastPayment) ? "0" : lastPayment }
                      <span className="prcy"> PRCY</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  initialState.mobileToggle && props.width <= 480
                    ? "p-10 mobileMode"
                    : "p-10"
                }
              >
                <div
                  className="apps"
                  style={{
                    backgroundImage: `url(${Background})`,
                    backgroundSize: "cover",
                  }}
                >
                  <img className="appIconss" src={appsIcon} alt="img" />
                  <div className="app-body">
                    <div className="app-body-total">Mobile Wallets</div>
                    <span className="app-body-amount">
                        Android/iOS
                    </span>
                  </div>
                  <div className="appIconsDiv">
                    <div className="appIcon" onClick={goToAndroid}>
                      <img src={googlePlay} alt="img" />
                    </div>
                    <div className="appIcon" onClick={goToiOS}>
                      <img src={appStore} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-sidebar">
              <div className="transactionTitleDiv">
                <div className="transactionTitle">Transactions</div>
                <div className="viewAllBtnDiv">
                  <button className="viewAllBtn" onClick={viewAllTransactions}>
                    View all
                    <img
                      className="rightArrowIcon"
                      src={rightArrow}
                      alt="img"
                    />
                  </button>
                </div>
              </div>
              <div className="scroll-trans">
                {props.data.map((transaction, index) => {
                  return (
                    <Transaction
                      key={transaction.txid}
                      txId={transaction.txid}
                      type={transaction.type}
                      amount={transaction.amount}
                      timestamp={transaction.timestamp}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <Alert
            open={warning}
            handleClose={warningClose}
            warningMessage={
              "Are sure you want to send " +
              state.amount +
              " PRCY to " +
              state.address +
              "?\n\nEstimated Transaction Fee: " +
              estimatedFee +
              "\nTotal Amount: " +
              (+state.amount + +estimatedFee)
            }
            src={warningAlert}
            title={"Send Coins"}
            color={"rgba(238, 255, 0, 0.03)"}
            onClick={onClickGotItBtn}
          />
          {initialState.txCreateResult !== null ? (
            initialState.txCreateResult.success ? (
              <Alert
                open={showModal}
                handleClose={modalClose}
                warningMessage={initialState.txCreateResult.message}
                title={"Congrats!"}
                src={successAlert}
                color={"rgba(0, 255, 170, 0.03)"}
                onClick={modalClose}
              />
            ) : (
              <Alert
                open={showModal}
                handleClose={modalClose}
                warningMessage={initialState.txCreateResult.message}
                title={"Ooops…"}
                src={errorAlert}
                color={"rgba(255, 0, 170, 0.03)"}
                onClick={modalClose}
              />
            )
          ) : null}
        </>
      )}
    </>
  );
});

const Checkbox = ({ label, value, onChange }) => {
  return (
      <input type="checkbox" checked={value} onChange={onChange} />
  );
};

export default withRouter(Dashboard);
