import React, { useContext, useRef, useState } from "react";
import Group from "../../assets/icons/Group.png";
import Copy from "../../assets/icons/Copy.png";
import QRCode from "../../assets/icons/QRCode.png";
import Context from "../../store/Store";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from '@material-ui/core/Tooltip';
import "./Header.css";

const Header = () => {
  const [state, dispatch] = useContext(Context);
  const addressRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => handleTooltipClose(), 2000);
  };

  const onClickGenerateQR = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: true,
    });
  };

  return (
    <div className="header">
      <div className="p-10">
        <div className="info">
          <div className="infoImageDiv">
            <img src={Group} alt="alt" />
          </div>
          <div className="masterAccountDiv">
            <label className="masterAccountLabel">Master Account</label>
            <div className="masterAccountKey">
              <div ref={addressRef} className="masterKeyInput">
                {state.wallet.address && state.wallet.length === 99 ?
                  state.wallet.address && state.wallet.address.substring(0, 15) + '...' + state.wallet.address &&
                  state.wallet.address.substring(99 - 15) :
                  state.wallet.address && state.wallet.address.substring(0, 15) + '...' + state.wallet.address.substring(99 - 15)}
              </div>
            </div>
          </div>


          <Tooltip
            open={open}
            title="Copied!"
          >
            <div className="copyIcon" onClick={handleTooltipOpen}>
              <CopyToClipboard
                text={state.wallet.address}
              >
                <img src={Copy} alt="alt" />
              </CopyToClipboard>
            </div>
          </Tooltip>


        </div>
      </div>
      <div className="p-10 text-right">
        <button className="qrGenerateBtn" onClick={onClickGenerateQR}>
          <img className="qrGenerateIcon" src={QRCode} alt="alt" />
          Generate QR
        </button>
      </div>
    </div>
  );
};

export default Header;
