import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Dashboard from "./dashboard/Dashboard";
import Transactions from "./transactions/Transactions";
import Masternodes from "./masternodes/Masternodes";
import Dex from "./dex/Dex";
import Header from "../components/header/Header";
import SpecsAndInfo from "./specsAndInfo/SpecsAndInfo";
import AddressModal from "../components/modals/AddressModal";
import { footerIcons } from "../constants/index";
import Context from "../store/Store";
import MobileHeader from "../components/header/MobileHeader"
import Help from "./help/Help";
import createActivityDetector from 'activity-detector';
import {
  Route,
  withRouter,
  useRouteMatch,
  Switch,
  useLocation,
} from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';
import "./MainContainer.css";
import { } from "../store/Reducer";

const MainContainer = (props) => {
  const [state, dispatch] = useContext(Context);
  const [width, setWidth] = useState(window.innerWidth);
  let { path, url } = useRouteMatch();
  let location = useLocation();
  const [active, setActive] = useState(location.pathname.substr(1));
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(true);
  const redirect = (event) => {
    setActive(event.currentTarget.dataset.value);
    props.history.push(`${url}${event.currentTarget.dataset.value}`);
  };
  const [show, setShow] = useState(true);
  var startActivityDetector = true;

  const handleUserIdle = () => {
    props.history.push("/login");
  };

  const handleUserActive = () => {
    console.log("user is active");
  };

  const activityDetector = createActivityDetector({
    timeToIdle: 600000, 
    autoInit: false,
    inactivityEvents: []
  });
  activityDetector.on('idle', handleUserIdle);
  activityDetector.on('active', handleUserActive);

  useEffect(() => {
    setActive(location.pathname.substr(1));
    window.addEventListener('resize', function (event) {
      setWidth(window.innerWidth)
    })
    var intervalId = 0;
    if (state.wallet.scanWalletTransactions) {
      if (state.isCreateWallet === true) {
        state.wallet.scannedHeight = state.currentBlock;
        console.log("Changed scanned height to: " + state.wallet.scannedHeight);
      }
      intervalId = setInterval(function scan() {
        state.wallet.scanWalletTransactions(() => {
          const tempData = cloneDeep(Object.values(state.wallet.transactionHistory))
          tempData.sort(function (x, y) {
            return y.timestamp - x.timestamp;
          })
          setData(tempData)
          setLoader(false);
          dispatch({
            type: "STORE_TRANSACTION_HISTORY",
            payload: state.wallet.transactionHistory,
          });
          setShow(false);
          setShow(true);
          if(startActivityDetector) {
            console.log("Activity Detector Started!");
            activityDetector.init();
            startActivityDetector = false;
          }
        });
        return scan;
      }(), 45000);
    } else {
      props.history.push("/login");
    }
    return (() => {
      clearInterval(intervalId);
      activityDetector.stop();
    });
  }, []);


  const handleClose = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: false,
    });
  };

  return (
    <div className={location.pathname === "/" ? "d-container" : "container"}>
      {
        <div className="header-mobile">
          <MobileHeader name={location.pathname.substr(1) === '' ? "Dashboard" : location.pathname.substr(1) === 'masternodes' ? "Masternode Hosts" : location.pathname.substr(1) === 'specs-and-info' ? "Specs And Info" : location.pathname.substr(1)} />
        </div>
      }
      {location.pathname !== "/" ? <Header /> : null}
      <Sidebar onClick={redirect} active={active} />
      <Switch>
        <Route exact path={path}>
          <Dashboard width={width} data={data && data} loader={loader} showBalance={show} />
        </Route>
        <Route exact path={`${path}specs-and-info`}>
          <SpecsAndInfo />
        </Route>
        <Route exact path={`${path}transactions`}>
          <Transactions />
        </Route>
        <Route exact path={`${path}help`}>
          <Help width={width} />
        </Route>
        <Route exact path={`${path}masternodes`}>
          <Masternodes width={width} />
        </Route>
        <Route exact path={`${path}dex`}>
          <Dex width={width} />
        </Route>
      </Switch>
      <AddressModal open={state.showModal} handleClose={handleClose} />
      <div className="footer">
        {footerIcons.map((icon) => {
          if ((location.pathname).substr(1) === icon.path) {
            return (
              <img
                key={icon.src}
                src={icon.selectedSrc}
                alt="img"
                onClick={redirect}
                data-value={icon.path}
                className="footerIcons"
              />)
          } else {
            return (
              <img
                key={icon.src}
                src={icon.src}
                alt="img"
                onClick={redirect}
                data-value={icon.path}
                className="footerIcons"
              />
            );
          }
        }
        )}
      </div>
    </div>
  );
};

export default withRouter(MainContainer);
