import React, { } from "react";
import SpecsAndInfo1 from "../../assets/images/specsAndInfo1.png";
import SpecsAndInfo2 from "../../assets/images/specsAndInfo2.png";
import summary from "../../assets/icons/summary.png";
//import rightArrow from "../../assets/icons/rightArrow.png";
import {
  specsAndInfoPara1,
  specsAndInfoPara2,
  shamefullMomentQuestion,
  shamefullMomentPoints,
  systemContent,
  summaryPoints,
  prcyCoinSpecsPoints
} from "../../constants/index";
import "./SpecsAndInfo.css";
//import Footer from "../../components/footer/Footer";

const SpecsAndInfo = (props) => {

  return (
    <div className="specsAndInfo-container">
      {/* <div className={`dropdown-specs ${open}`}>
        <span>Specs and information</span>
        <span className="arrow"><img className="masternodeDownArrow" alt="img" src={rightArrow} onClick={openDropdown}/></span>
        <div className="innerDrop">
          <div class="specsAndInfoRightBar">
            <div class="selectedRightBarListDiv">
              <div class="rightBarList" id="specsAndInformation">Specs and information</div>
            </div>
            <div class="rightBarSubList">
              <div class="sublist">Getting started</div>
              <div class="sublist">Managing</div>
              <div class="sublist">Sending and receiving</div>
              <div class="sublist">Other Topics</div>
            </div>
            <div class="rightBarListDiv">
              <div class="rightBarList" id="wallets">Wallets</div>
            </div>
            <div class="rightBarListDiv">
              <div class="rightBarList" id="masternodeInfo">Masternode info</div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="specsAndInfoContent">
        <div className="specsAndInfoContentDiv">
          <div className="specsAndInfoHeading">Specs and information</div>
          <div className="specsAndInfoPara">{specsAndInfoPara1}</div>
          <div className="specsAndInfo1ImageDiv">
            <img className="specsAndInfoImage" src={SpecsAndInfo1} alt="img" />
          </div>
          <div className="specsAndInfoPara"><span className="dropCap">I</span>{specsAndInfoPara2}</div>
          <div className="specsAndInfo2ImageDiv">
            <img className="specsAndInfoImage" src={SpecsAndInfo2} alt="img" />
          </div>
          <div className="captionPlaceholder"><a href="https://prcycoin.com/whitepaper/" target="_blank" rel="noopener noreferrer" className="termsTextHelp termsText">Whitepaper</a></div>
          <div className="shamefullMomentsDiv">
            <div className="shamefullMomentsHeading">
              {shamefullMomentQuestion}
            </div>
            <div className="shamefullMomentsPoints">
              {shamefullMomentPoints.map((point, index) => {
                  return (
                    <>
                      <div className="summaryPointDiv">
                        <div className="summaryIcon">{++index}.</div>
                        <div className="systemContent">{point}</div>
                      </div>
                    </>
                  );
              })}
            </div>
          </div>
          <div className="systemDiv">
            <div className="systemHeading">What Makes PRCY Unique?</div>
            <div className="systemContent">{systemContent}</div>
          </div>
          <div className="specsAndInfoHeading">PRivaCY Coin Specs</div>
          <div className="shamefullMomentsDiv">
            
            {prcyCoinSpecsPoints.map((point, index) => {
              return (
                <>
                  <div className="summaryPointDiv">
                    <div className="bullets">.</div>
                    <div className="systemContent">{point}</div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="summaryDiv">
            <div className="systemHeading">Summary</div>
            {summaryPoints.map((point) => {
              return (
                <>
                  <div className="summaryPointDiv">
                    <img className="summaryIcon" src={summary} alt="img" />
                    <div className="systemContent">{point}</div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        {/* <div className="specsAndInfoFooter">
          <Footer />
        </div> */}
      </div>
      {/* <div className="specsAndInfoRightBar">
        <div
          className={
            showSublist === "specsAndInformation"
              ? "selectedRightBarListDiv"
              : "rightBarListDiv"
          }
        >
          <div
            className="rightBarList"
            id="specsAndInformation"
            onClick={onClickListItem}
          >
            Specs and information
          </div>
        </div>
        <div className="rightBarSubList">
          {showSublist === "specsAndInformation"
            ? rightbarSubList.map((sublist) => {
                return <div className="sublist">{sublist}</div>;
              })
            : null}
        </div>
        <div
          className={
            showSublist === "wallets"
              ? "selectedRightBarListDiv"
              : "rightBarListDiv"
          }
        >
          <div className="rightBarList" id="wallets" onClick={onClickListItem}>
            Wallets
          </div>
        </div>
        <div
          className={
            showSublist === "masternodeInfo"
              ? "selectedRightBarListDiv"
              : "rightBarListDiv"
          }
        >
          <div
            className="rightBarList"
            id="masternodeInfo"
            onClick={onClickListItem}
          >
            Masternode info
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default SpecsAndInfo;
