import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import close from "../../assets/icons/close.png";

const Alert = (props) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 100,
    },
    paper: {
      width: "350px",
      height: "auto",
      borderRadius: "24px",
      backgroundImage: "linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%)",
      outline: "none",
      padding: "40px",
    },
    modalHeading: {
      fontSize: "30px",
      color: "white",
      fontWeight: 700,
      margin: "0px",
      marginBottom: "15px",
      letterSpacing: 1,
    },
    alertIconContainer: {
      display: "grid",
      gridTemplateColumns: "90% 10%",
    },
    alertIconDiv: {
      width: "100px",
      height: "100px",
      borderRadius: "12px",
      backgroundColor: props.color,
      marginBottom: "40px",
    },
    alertIcon: {
      padding: "30px",
    },
    closeIcon: {
      float: "right",
      padding: "15px 5px 5px 5px",
      cursor: "pointer",
      opacity: "0.5",
      height: "35px",
      width: "24px"
    },
    dashBtnDiv: {
      marginTop: "30px",
    },
    yesBtn: {
      borderRadius: "12px",
      backgroundImage: "linear-gradient(45deg, rgba(254,79,0,1) 0.99%, rgba(255,172,0,1) 56.16%,rgba(255,135,1,1) 98.52%)",
      border: "none",
      float: "right",
      cursor: "pointer",
      color: "white",
      fontSize: "16px",
      outline: 0,
      width: "94px",
      height: "48px",
    },
    noBtn: {
      borderRadius: "12px",
      backgroundImage: "linear-gradient(45deg, rgba(254,79,0,1) 0.99%, rgba(255,172,0,1) 56.16%,rgba(255,135,1,1) 98.52%)",
      border: "none",
      float: "left",
      cursor: "pointer",
      color: "white",
      fontSize: "16px",
      outline: 0,
      width: "94px",
      height: "48px",
    },
    messageDiv: {
      color: "rgba(224, 224, 255, 0.6)",
      fontSize: "16px",
      marginTop: "30px",
      lineHeight: 1.50,
      letterSpacing: 1,
      wordBreak: "break-word",
      whiteSpace: "pre-line"
    },
  }));
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className={classes.alertIconContainer}>
            <div className={classes.alertIconDiv}>
              <img className={classes.alertIcon} src={props.src} alt="img" />
            </div>
            <div onClick={props.handleClose}>
              <img className={classes.closeIcon} src={close} alt="img" />
            </div>
          </div>
          <div>
            <h1 className={classes.modalHeading}>{props.title}</h1>
          </div>
          <div className={classes.messageDiv}>{props.warningMessage}</div>
          <div className={classes.dashBtnDiv}>
            <button className={classes.yesBtn} onClick={props.onClick}>Yes</button>
            <button className={classes.noBtn} onClick={props.handleClose}>No</button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default Alert;
