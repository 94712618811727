import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import close from "../../assets/icons/close.png";
import Group from "../../assets/icons/Group.png";
import QRCode from "qrcode.react";
import Context from "../../store/Store";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
  },
  paper: {
    width: "350px",
    borderRadius: "24px",
    boxShadow:
      "32px 32px 96px 0 rgba(9, 14, 20, 0.4), -16px -16px 32px 0 rgba(224, 224, 255, 0.04), 0 16px 16px 0 rgba(9, 14, 20, 0.4)",
    backgroundImage: "linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%, rgba(0,51,51,1) 98.52%)",
    outline: "none",
    padding: "40px",
  },
  modalHeading: {
    fontSize: "36px",
    color: "white",
    fontWeight: 700,
    margin: "0px",
    marginBottom: "20px",
  },
  headingDiv: {
    display: "grid",
    gridTemplateColumns: "90% 10%",
  },
  closeIcon: {
    float: "right",
    padding: "15px 5px 5px 5px",
    cursor: "pointer",
    opacity: "0.5",
    height: "35px",
    width: "24px"
  },
  qrCodeImageDiv: {
    marginTop: "15px",
  },

  qrCodeImage: {
    width: "100%",
    height: "310px",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "30px",
  },
  infoImageDiv: {
    borderRadius: "10px",
    boxShadow: `8px 8px 24px 0 rgba(9, 14, 20, 0.4),
      -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4)`,
    backgroundImage: `linear-gradient(137deg, #2d2f33 1%, #272a2e 52%, #222529)`,
    padding: "8px 11px",
    '& img': {
      opacity: "0.5"
    }
  },
  masterAccountDiv: {
    padding: "20px",
  },
  masterAccountLabel: {
    color: "rgba(224, 224, 255, 0.6)",
    fontSize: "13px",
  },
  masterAccountKey: {
    fontSize: "14px",
    color: "#ffffff",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  shareAddressBtnDiv: {
    textAlign: "center",
    marginTop: "20px",
  },
  shareAddressBtn: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#4579f5",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
  groupIcon: {
    maxHeight: "20px !important",
    maxWidth: "20px !important",
  },
  qrCodeImageImg: {
    width: "100%"
  }
}));

const AddressModal = (props) => {
  const [state] = useContext(Context);
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => handleTooltipClose(), 2000);
  };



  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className={classes.headingDiv}>
            <h1 className={classes.modalHeading}>Address</h1>
            <div onClick={props.handleClose}>
              <img className={classes.closeIcon} src={close} alt="img" />
            </div>
          </div>
          <div className={classes.qrCodeImageDiv}>
            <QRCode className={classes.qrCodeImageImg} size="270" value={state.wallet.address} />
          </div>
          <div className={classes.info}>
            <div className={classes.infoImageDiv}>
              <img className={classes.groupIcon} src={Group} alt="alt" />
            </div>
            <div className={classes.masterAccountDiv}>
              <label className={classes.masterAccountLabel}>
                Master Account
              </label>
              {state.wallet && (
                <div className={classes.masterAccountKey}>
                  {state.wallet.address && state.wallet.length === 99 ? 
                state.wallet.address && state.wallet.address.substring(0, 15) + '...' + state.wallet.address && 
                state.wallet.address.substring(99 - 15): 
                state.wallet.address && state.wallet.address.substring(0, 15) + '...' + state.wallet.address.substring(110 - 15)}
                </div>
              )}
            </div>
          </div>
          <Tooltip
            open={open}
            title="Copied!"
          >
            <div className={classes.shareAddressBtnDiv} onClick={handleTooltipOpen}>
            <CopyToClipboard
              text={state.wallet.address}
            >
              <button className={classes.shareAddressBtn}>Copy Address</button>
              </CopyToClipboard>
            </div>
          </Tooltip>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddressModal;
