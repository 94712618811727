import React, {useContext, useEffect, useState} from "react";
import { CircularProgress } from "@material-ui/core";
import "./Loader.css";
import Context from "../../store/Store";

const Loader = () => {
  const [initialState] = useContext(Context);
  const [loaderText, setText] = useState(initialState.wallet.currentStatusValue);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setText(initialState.wallet.currentStatusValue);
    }, 6000);
    return () => clearInterval(intervalId);
  }, [initialState.wallet.currentStatusValue]);

  const renderText = (param) => {
    
    switch (param) {
      case 1:
        return "Loading Your PRCY Wallet!";
      case 2:
        return "Wallet is currently scanning the chain for your UTXO’s. If this is the first time you are using the wallet, this may take up to 10 minutes.";
      case 3:
        return "Wallet is currently processing your UTXO’s to calculate your balance.";
      case 4:
        return "Wallet is generating your transaction history. This may take some time if you have many transactions.";
      case 5:
        return "Wallet is currently recomputing your balance.";
      default:
        return "It takes some time to load the page. Soon you'll be able to use your wallet.";
    }
  };

  return (
    <div className="loaderDiv">
      <CircularProgress />
      <div>
        <div className="loaderHeading">Please wait</div>
      </div>
      
      <div className="loaderMessage">
        { renderText(loaderText) }
      </div>
    </div>
  );
};

export default Loader;
