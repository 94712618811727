import React from "react";
import logout from "../../assets/icons/logout.png";
import newLogo from "../../assets/images/new-logo.png"
import { sidebarMenuItems } from "../../constants/index";
import { withRouter } from "react-router-dom";
import "./Sidebar.css";
import {  } from "history";
const config = require("prcyjs").Constants;

const Sidebar = (props) => {
  const onClickLogoutBtn = () => {
    props.history.push("/login");
  };

  return (
    <div className="sidebar">
      <div className="logo">PRivaCY</div>
      <div className="menu">
        <ul className="items">
          {sidebarMenuItems.map((menuItem, index) => {
            return (
              <div
                className={
                  props.active === menuItem.path ? "selectedItemDiv" : "itemDiv"
                }
                data-value={menuItem.path}
                onClick={props.onClick}
              >
                <img src={menuItem.src} alt="img" className="menuItemIcon" />
                <div
                  className="item"
                  key={index}
                  data-value={menuItem.path}
                >
                  {menuItem.name}
                </div>
              </div>
            );
          })}
        </ul>
        {
          config.PRCYCHAIN === "testnet" ? <div className="logo">Network: <br />{config.PRCYCHAIN.charAt(0).toUpperCase() + config.PRCYCHAIN.slice(1)}</div> :
            <img src={newLogo} alt="img" className="new-logo" />
        }
        <ul className="items">
          <div className="logoutDiv" onClick={onClickLogoutBtn}>
            <img src={logout} alt="img" className="menuItemIcon" />
            <div className="item">
              Log out
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
