import React, { useContext } from "react";
import Context from "../../store/Store";
import dashboardHelp from "../../assets/icons/group-copy-2@3x.png"
import balance from "../../assets/icons/group@3x.png"
import help from "../../assets/icons/help.png"
import total from "../../assets/icons/total.png"
import "./MobileHeader.css";

const MobileHeader = (props) => {
  const [state, dispatch] = useContext(Context);

  const onClickToggleBtn = (val) => {
    dispatch({
      type: "SET_TOGGLE",
      payload: val,
    });
  }

  return (
    <div className="dashboardHeader"> 
    <div className="header-mobile-heading">
    {props.name}</div>
    {props.name === "Dashboard" || props.name === "help" ? 
    <div className="dashboardToggleBtn">
      <div
          className={!state.mobileToggle ? "balanceMobileActive" :"balanceMobile"}
          onClick={() => onClickToggleBtn(true)}
        >
          <img  className="mobileHeaderIcon" src={props.name === "Dashboard" ? balance: total} alt="img" />
        </div>
        <div
          className={state.mobileToggle ? "balanceMobileActive" :"balanceMobile"}
          onClick={() => onClickToggleBtn(false)}
        >
          <img className="mobileHeaderIcon" src={props.name === "Dashboard" ? dashboardHelp: help} alt="img" />
        </div>
    </div>: null}
  </div>
  );
};

export default MobileHeader;
