import React, { useContext, useEffect, useState } from "react";
import "./Dex.css";
import bprcy from "../../assets/images/coins/bprcy.png";
import pprcy from "../../assets/images/coins/pprcy.png";
import tprcy from "../../assets/images/coins/tprcy.png";
import wprcy from "../../assets/images/coins/wprcy.png";
import arrr from "../../assets/images/coins/arrr.png";
import bch from "../../assets/images/coins/bch.png";
import bnb from "../../assets/images/coins/bnb.png";
import btc from "../../assets/images/coins/btc.png";
import bttold from "../../assets/images/coins/bttold.png";
import busd from "../../assets/images/coins/busd.png";
import busd_bep20 from "../../assets/images/coins/busd_bep20.png";
import dash from "../../assets/images/coins/dash.png";
import dgb from "../../assets/images/coins/dgb.png";
import doge from "../../assets/images/coins/doge.png";
import eth from "../../assets/images/coins/eth.png";
import firo from "../../assets/images/coins/firo.png";
import ftm from "../../assets/images/coins/ftm.png";
import ltc from "../../assets/images/coins/ltc.png";
import pivx from "../../assets/images/coins/pivx.png";
import prv from "../../assets/images/coins/prv.png";
import rvn from "../../assets/images/coins/rvn.png";
import shib from "../../assets/images/coins/shib.png";
import swap from "../../assets/images/coins/swap.png";
import trx from "../../assets/images/coins/trx.png";
import tusd from "../../assets/images/coins/tusd.png";
import txbit from "../../assets/images/coins/txbit.png";
import usdc_erc20 from "../../assets/images/coins/usdc_erc20.png";
import usdc_bep20 from "../../assets/images/coins/usdc_bep20.png";
import usdt_erc20 from "../../assets/images/coins/usdt_erc20.png";
import usdt_bep20 from "../../assets/images/coins/usdt_bep20.png";
import usdt_trc20 from "../../assets/images/coins/usdt_trc20.png";
import wow from "../../assets/images/coins/wow.png";
import xmr from "../../assets/images/coins/xmr.png";
import xrp from "../../assets/images/coins/xrp.png";
import xvg from "../../assets/images/coins/xvg.png";
import zec from "../../assets/images/coins/zec.png";
import dex from "../../assets/images/Dex.png";
import rightArrow from "../../assets/icons/rightArrow.png";
import activeListings from "../../assets/icons/activeListings.png";
import { withRouter } from "react-router-dom";
import API from "../../api/Api";
import Context from "../../store/Store";
import { CircularProgress } from "@material-ui/core";

const Dex = (props) => {
  const [loader, setLoader] = useState(true);
  const [state] = useContext(Context);
  useEffect(() => {
    API.get("https://explorer.prcycoin.com/api/getmasternodecount").then(
      (result) => {
        setLoader(false);
      }
    );
  });

  const redirectToDashboard = () => {
    props.history.push("/");
  };

  const goToDex = () => {
    window.open('https://privacydex.io', '_blank');
  };

  const goToCoin = (coin) => {
    window.open('https://privacydex.io/Swap?from=' + coin + '&to=PRCY&address=' + state.wallet.address, '_blank');
  };

  const goToUnWrapped = (coin) => {
    window.open('https://privacydex.io/Swap?from=PRCY&to=' + coin, '_blank');
  };

  return (
    <>
      <div className="dex-container">
        <div className="coming-soon">
          <div className={props.width <= 480 ? "soon-heading mobileMode" : "soon-heading" }>PRivaCY DEX</div>
          <div className="soon-para">
            Swap any coins below for PRivaCY Coin
          </div>
          <button className="backToDashboardBtn" onClick={redirectToDashboard}>
            Back to Dashboard
          </button>
        </div>
        <div className="node" onClick={goToDex}>
          <img className="Markets" src={dex} alt="img" />
          <div className="visitDex" >
            <div className="visitMarkets">
              Check Out <br /> Our DEX
            </div>
            <img className="dexRightArrow" alt="img" src={rightArrow} />
          </div>
        </div>
        <div className="node">
          <div className="activeListingsImageDiv">
            <img
              className="activeListingsImage"
              src={activeListings}
              alt="img"
            />
          </div>
          {loader ? (
            <CircularProgress />
          ) : (
            <div className="amountOfActivePairs" onClick={goToDex}>36</div>
          )}
          {
            props.width <= 480 ? <div className="activeListings" onClick={goToDex}>Listed Assets</div>:
            <div className="activeListings" onClick={goToDex}>Listed Assets</div>
          }
        </div>
        <div className="company" onClick={() => goToCoin('BPRCY')}>
          <img src={bprcy} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('PPRCY')}>
          <img src={pprcy} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('TPRCY')}>
          <img src={tprcy} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('WPRCY')}>
          <img src={wprcy} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('BNB')}>
          <img src={bnb} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('BUSD')}>
          <img src={busd} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('BTC')}>
          <img src={btc} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('BCH')}>
          <img src={bch} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('BSC-BTT-OLD')}>
          <img src={bttold} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('BSC-BUSD')}>
          <img src={busd_bep20} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('DASH')}>
          <img src={dash} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('DGB')}>
          <img src={dgb} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('DOGE')}>
          <img src={doge} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('ETH')}>
          <img src={eth} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('FTM')}>
          <img src={ftm} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('FIRO')}>
          <img src={firo} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('PRV')}>
          <img src={prv} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('LTC')}>
          <img src={ltc} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('XMR')}>
          <img src={xmr} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('ARRR')}>
          <img src={arrr} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('PIVX')}>
          <img src={pivx} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('RVN')}>
          <img src={rvn} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('SWAP')}>
          <img src={swap} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('SHIB')}>
          <img src={shib} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('TRX')}>
          <img src={trx} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('BSC-TUSD')}>
          <img src={tusd} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('TXBIT')}>
          <img src={txbit} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('USDC')}>
          <img src={usdc_erc20} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('BSC-USDC')}>
          <img src={usdc_bep20} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('USDT')}>
          <img src={usdt_erc20} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('BSC-USDT')}>
          <img src={usdt_bep20} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('TRX-USDT')}>
          <img src={usdt_trc20} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('XVG')}>
          <img src={xvg} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('WOW')}>
          <img src={wow} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('XRP')}>
          <img src={xrp} alt="img" />
        </div>
        <div className="company" onClick={() => goToCoin('ZEC')}>
          <img src={zec} alt="img" />
        </div>
        <br /><br />
      </div>
    </>
  );
};

export default withRouter(Dex);
