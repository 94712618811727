import React, { useState } from "react";
import plus from "../../assets/icons/plus.png";
import minus from "../../assets/icons/minus.png";
import "./Question.css";

const Question = (props) => {
  const [showAnswer, setShowAnswer] = useState('');
  const expandAnswer = () => {
    if (showAnswer === '') {
      setShowAnswer('show')
    }
    else {
      setShowAnswer('')
    }
  };

  return (
    <>
      {!showAnswer ? (
        <div className="questionDiv">
          <div className="question">{props.question.ques}</div>
          <img
            className="plusIcon"
            src={plus}
            alt="img"
            onClick={() => expandAnswer()}
          />
        </div>
      ) : (
          <div className={showAnswer === '' ? "expandQuestionContainer" : "expandQuestionContainer show"}>
            <div className="expandQuestionDiv">
              <div className="expandQuestion">
                {props.question.ques}
              </div>
              <img
                className="minusIcon"
                src={minus}
                alt="img"
                onClick={() => expandAnswer()}
              />
            </div>
            <div className="expandAnswer">
              {props.question.ans}
            </div>
          </div>
        )}
    </>
  );
};

export default Question;
