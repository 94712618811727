const Wallet = require("prcyjs").Wallet;
const config = require("prcyjs").Constants;

export const initialState = {
  isLogIn: false,
  wallet: {},
  transactionHistory: [],
  showModal: false,
  prcyPrice: 0,
  prcyChange: 0,
  txCreateResult: null,
  mobileToggle: true,
  currentBlock: 0,
  isCreateWallet: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      let walletObj;
      if (action.payload.bitcoinSeed) {
        walletObj = new Wallet(
          { mnemonics: action.payload.mnemonics },
          config.PRCY_SERVER,
          config.PRCYCHAIN,
          "Bitcoin seed"
        );
      } else {
        walletObj = new Wallet({ mnemonics: action.payload.mnemonics });
      }
      return {
        ...state,
        isLogIn: true,
        wallet: walletObj,
      };
    case "STORE_TRANSACTION_HISTORY":
      return {
        ...state,
        transactionHistory: action.payload,
      };
    case "SHOW_MODAL":
      return {
        ...state,
        showModal: action.payload,
      };
    case "SET_PRICE":
      return {
        ...state,
        prcyPrice: action.payload,
      };
    case "SET_CHANGE":
      return {
        ...state,
        prcyChange: action.payload,
      };
    case "SEND_TO_ADDRESS":
      return {
        ...state,
        txCreateResult: action.payload,
      };
    case "SET_TOGGLE":
      return {
        ...state,
        mobileToggle: action.payload,
      };
    case "EMPTY_OBJECT": {
      return {
        ...state,
        txCreateResult: null
      }
    }
    case "CURRENT_BLOCK": {
      return {
        ...state,
        currentBlock: action.payload,
      }
    }
    case "CREATE_WALLET": {
      return {
        ...state,
        isCreateWallet: action.payload,
      }
    }
    default: {
      return null;
    }
  }
};
