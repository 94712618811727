import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import close from "../../assets/icons/close.png";
import copyMnemonics from "../../assets/icons/Copy.png";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
    fontFamily: "Montserrat",
  },
  paper: {
    width: "540px",
    borderRadius: "24px",
    boxShadow:
      "32px 32px 96px 0 rgba(9, 14, 20, 0.4), -16px -16px 32px 0 rgba(224, 224, 255, 0.04), 0 16px 16px 0 rgba(9, 14, 20, 0.4)",
    backgroundImage: "linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%, rgba(0,51,51,1) 98.52%)",
    outline: "none",
    padding: "40px",
    margin: "10px"
  },
  modalHeading: {
    fontSize: "36px",
    color: "white",
    fontWeight: 700,
    margin: "0px",
    marginBottom: "20px",
  },
  headingDiv: {
    display: "grid",
    gridTemplateColumns: "90% 10%",
  },
  closeIcon: {
    float: "right",
    padding: "15px 5px 5px 5px",
    cursor: "pointer",
    opacity: "0.5",
    height: "35px",
    width: "24px"
  },
  loginInputField: {
    height: "160px",
    borderRadius: "12px",
    backgroundColor: "rgba(224, 224, 255, 0.02)",
    border: "none",
    width: "97%",
    padding: "10px",
    color: "white",
    resize: "none",
    fontSize: "16px",
    overflow: "hidden",
    outline: 0,
    fontFamily: "Montserrat",
    lineHeight: "24px",
    overflowY: "scroll",
    MsOverflowStyle: "none",  /* IE and Edge */
    scrollbarWidth: "none"  /* Firefox */
  },
  copyMessage: {
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "1.23",
    color: "rgba(224, 224, 255, 0.4)",
    paddingLeft: "15px",
  },
  copyBtnDiv: {
    marginTop: "30px",
  },
  copyBtn: {
    height: "48px",
    borderRadius: "12px",
    backgroundImage:
      "linear-gradient(45deg, rgba(254,79,0,1) 0.99%, rgba(255,172,0,1) 56.16%,rgba(255,135,1,1) 98.52%)",
    border: "none",
    float: "right",
    cursor: "pointer",
    color: "white",
    fontSize: "16px",
    outline: 0,
    display: 'inline-flex',
    alignItems: "center",
    justifyContent: 'center',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  copyIcon: {
    marginRight: "10px",
    height: "24px",
    width: "24px"
  },
  loginInputField___webkit_scrollbar: { 
      display: "none" 
  }
}));

const CreateWalletModal = (props) => {
  const classes = useStyles();
  const textAreaRef = useRef(null);

  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand("copy");
    props.handleClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className={classes.headingDiv}>
            <h1 className={classes.modalHeading}>Create new wallet</h1>
            <div onClick={props.handleClose}>
              <img className={classes.closeIcon} src={close} alt="img" />
            </div>
          </div>
          <div className={classes.copyMessage}>
            <p>Copy your 24 word seed phrase</p>
          </div>
          <div>
            <textarea
              ref={textAreaRef}
              className={classes.loginInputField}
              type="text"
              name="mnemonics"
              defaultValue={props.mnemonics}
            />
          </div>
          <div className={classes.copyBtnDiv}>
            <button className={classes.copyBtn} onClick={copyToClipboard}>
              <img className={classes.copyIcon} src={copyMnemonics} alt="img" />
              Copy and Login
            </button>
          </div>
        </div>
      </Fade>

    </Modal>
  );
};

export default CreateWalletModal;
