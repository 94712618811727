import React from "react";
import helpImage from "../../assets/images/helpImage.png";
//import paidFrom from "../../assets/icons/paidFrom.png";
//import wallet from "../../assets/icons/wallet.png";
//import masternodes from "../../assets/icons/masternodes.png";
//import rightArrow from "../../assets/icons/rightArrow.png";
import Question from "./Question";
import { withRouter } from "react-router-dom";
import "./Help.css";
import Context from '../../store/Store';
import { useContext } from "react";
import { faqs } from "../../constants/index";

const Help = (props) => {
  const [state] = useContext(Context)

  const onClickReadBtn = () => {
    props.history.push("/specs-and-info")
  }

  return (
    <div className="help-container">
      <div className={!state.mobileToggle && props.width <= 480 ? "helpContent mobileMode" : "helpContent"}>
        <div className="read" >
          <div className="helpImageDiv" onClick={onClickReadBtn}>
            <img className="helpImage" alt="img" src={helpImage} />
          </div>
          <div className="helpHeading specsHeading" onClick={onClickReadBtn}>
            Specs and Information
          </div>
          <div className="helpParagraph" onClick={onClickReadBtn}>
            PRCY is a hybrid privacy blockchain, which provides enhanced security, scalability along with total privacy. 
          </div>
          <div className="helpReadBtnDiv" onClick={onClickReadBtn}>
            <span className="date"></span>
            <div>
              <button className="readBtn">READ</button>
            </div>
          </div>
          <a href="https://prcycoin.com/knowledge-base/" target="_blank" rel="noopener noreferrer" className="termsTextHelp termsText">Knowledge Base</a>
        </div>
        <div className="mainTopics">
          <div className="helpHeading">Useful Links</div>
          {/* <div className="topicsDiv">
            <div className="topic" onClick={onClickSpecsAndInfo}>
              <img className="balanceIcons" alt="img" src={paidFrom} />
              <div className="topicName">
                Specs and information
              </div>
              <div>
                <img className="helpRightArrow" alt="img" src={rightArrow} />
              </div>
            </div>
            <div className="topic">
              <img className="balanceIcons" alt="img" src={wallet} />
              <div className="topicName">Wallets</div>
              <div>
                <img className="helpRightArrow" alt="img" src={rightArrow} />
              </div>
            </div>
            <div className="topic">
              <img className="balanceIcons" alt="img" src={masternodes} />
              <div className="topicName">Masternodes Info</div>
              <div>
                <img className="helpRightArrow" alt="img" src={rightArrow} />
              </div>
            </div>
          </div> */}
          <div>
            <div className="fact"><div className="bullets">.</div><span className="factText"><a href="https://privacydex.io/" target="_blank" rel="noopener noreferrer" className="termsTextHelp termsText">PRivaCYdex.io</a></span></div>
            <div className="fact"><div className="bullets">.</div><span className="factText"><a href="https://comparison.prcycoin.com/" target="_blank" rel="noopener noreferrer" className="termsTextHelp termsText">Comparison</a></span></div>
            <div className="fact"><div className="bullets">.</div><span className="factText"><a href="https://retention.prcycoin.com/" target="_blank" rel="noopener noreferrer" className="termsTextHelp termsText">Retention Plan</a></span></div>
          </div>

        </div>
        
      </div>
      <div className={state.mobileToggle && props.width <= 480 ? "faq mobileMode" : "faq"}>
        {faqs.map((question) => {
          return <Question question={question} />;
        })}
      </div>
    </div>
  );
};

export default withRouter(Help);
