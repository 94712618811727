import React, { useReducer, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "./store/Store";
import { reducer, initialState } from "./store/Reducer";
import Routes from "./Routes";
import API from "./api/Api";
import { current_block_api } from "./constants/index"
const config = require("prcyjs").Constants;

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    API.get(current_block_api).then((result) => {
      dispatch({
        type: "CURRENT_BLOCK",
        payload: result,
      });
    });
    API.get(config.PRICE_COINGECKO).then((result) => {
      dispatch({
        type: "SET_PRICE",
        payload: result["prcy-coin"].usd,
      });
    });
    API.get(config.PRICE_COINGECKO).then((result) => {
      dispatch({
        type: "SET_CHANGE",
        payload: result["prcy-coin"].usd_24h_change,
      });
    });
  }, []);

  return (
    <Provider value={[state, dispatch]}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  );
}

export default App;
