import React, { useEffect, useState } from "react";
import "./Masternodes.css";
import cmp1 from "../../assets/images/group-9@3x.png";
import cmp2 from "../../assets/images/group-10@3x.png";
import cmp3 from "../../assets/images/group-12@3x.png";
import cmp4 from "../../assets/images/group-13@3x.png";
import Markets from "../../assets/images/Markets.png";
import rightArrow from "../../assets/icons/rightArrow.png";
import activeMasternodes from "../../assets/icons/activeMasternodes.png";
import { withRouter } from "react-router-dom";
import API from "../../api/Api";
import { CircularProgress } from "@material-ui/core";

const Masternodes = (props) => {
  const [loader, setLoader] = useState(true);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    API.get("https://explorer.prcycoin.com/api/getmasternodecount").then(
      (result) => {
        setTotal(((result.total)).toFixed(0));
        setLoader(false);
      }
    );
  });

  const redirectToDashboard = () => {
    props.history.push("/");
  };

  const goToMarkets = () => {
    window.open('https://prcycoin.com/exchange/', '_blank');
  };

  const goToHiglan = () => {
    window.open('https://higlan.com/', '_blank');
  };

  const goToMNO = () => {
    window.open('https://masternodes.online/currencies/PRCY/', '_blank');
  };

  const goToMNBiz = () => {
    window.open('https://masternodes.biz/cryptocurrency/PRCY', '_blank');
  };

  const goToPecunia = () => {
    window.open('https://pecuniaplatform.io/ref/f8556ab0', '_blank');
  };

  const goToMasternodeList = () => {
    window.open('https://explorer.prcycoin.com/masternodes', '_blank');
  };

  return (
    <>
      <div className="masternodes-container">
        <div className="coming-soon">
          <div className={props.width <= 480 ? "soon-heading mobileMode" : "soon-heading" }>Masternode Hosts</div>
          <div className="soon-para">
            PRCY currently has these <br />
            masternode hosts
          </div>
          <button className="backToDashboardBtn" onClick={redirectToDashboard}>
            Back to Dashboard
          </button>
        </div>
        <div className="node" onClick={goToMarkets}>
          <img className="Markets" src={Markets} alt="img" />
          <div className="visitOnMasternode" >
            <div className="visitMarkets">
              Check Out <br /> Our Markets
            </div>
            <img className="masternodeRightArrow" alt="img" src={rightArrow} />
          </div>
        </div>
        <div className="node">
          <div className="activeMasternodeImageDiv">
            <img
              className="activeMasternodeImage"
              src={activeMasternodes}
              alt="img"
            />
          </div>
          {loader ? (
            <CircularProgress />
          ) : (
            <div className="amountOfActiveMasternodes" onClick={goToMasternodeList}>{`${total && total}`}</div>
          )}
          {
            props.width <= 480 ? <div className="activeMasternodes" onClick={goToMasternodeList}>ACTIVE NODES</div>:
            <div className="activeMasternodes" onClick={goToMasternodeList}>ACTIVE MASTERNODES</div>
          }
        </div>
        <div className="company" onClick={goToHiglan}>
          <img src={cmp1} alt="img" />
        </div>
        <div className="company" onClick={goToMNO}>
          <img src={cmp2} alt="img" />
        </div>
        <div className="company" onClick={goToMNBiz}>
          <img src={cmp3} alt="img" />
        </div>
        <div className="company" onClick={goToPecunia}>
          <img src={cmp4} alt="img" />
        </div>
      </div>
    </>
  );
};

export default withRouter(Masternodes);
