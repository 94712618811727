import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import Context from "../../store/Store";
import cloneDeep from 'lodash/cloneDeep';
import { withRouter } from 'react-router-dom'
const PrcyUtils = require("prcyjs").Utils;

const GridView = (props) => {
  const [state] = useContext(Context);
  const [data, setData] = useState([]);

  useEffect(() => {
    if(state.wallet.transactionHistory) {
      const tempData = cloneDeep(Object.values(state.wallet.transactionHistory))
      tempData.sort(function(x, y){
        return y.timestamp - x.timestamp;
      })
      tempData.forEach((transaction) => {
        const date = convertIntoTime(transaction.timestamp);
        transaction.dateAndTime = date;
        transaction.amountInUsd = (PrcyUtils.balanceInPRCY(transaction.amount) * state.prcyPrice).toFixed(4);
        transaction.amount = `${PrcyUtils.balanceInPRCY(transaction.amount)} PRCY`
        transaction.txid = transaction.txid.substring(0, 6) + '...' + transaction.txid.substring(64 - 4)
        transaction.type = transaction.type.toUpperCase()
      });
      setData(tempData)
    } else {
      props.history.push("/login");
    }
  }, [props.history, state.prcyPrice, state.wallet.transactionHistory]);

  const convertIntoTime = (timestamp) => {
    const date_ob = new Date(timestamp * 1000);
    const year = date_ob.getFullYear();
    const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    const date = ("0" + date_ob.getDate()).slice(-2);
    const hours = ("0" + date_ob.getHours()).slice(-2);
    const minutes = ("0" + date_ob.getMinutes()).slice(-2);
    const seconds = ("0" + date_ob.getSeconds()).slice(-2);
    return (
      year +
      "-" +
      month +
      "-" +
      date +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };
  const customStyles = {
    headRow: {
      style: {
        borderBottomStyle: "none",
      },
    },
    rows: {
      style: {
        minHeight: "48px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "none",
        },
      },
    },
    headCells: {
      style: {
        padding: "20px 20px 30px 20px ",
        fontSize: "14px",
        fontWeight: 700,
        backgroundColor: "rgb(1,40,40)",
        //background: "linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%)",
        color: "white",
        letterSpacing: 1,
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "rgb(1,40,40)",
        //background: "linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%)",
        color: "#9496aa",
        fontSize: "13px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "rgb(1,40,40)",
        // background: "linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%)",
        color: "#9496aa",
        borderTop: "none",
      },
    },
  };

  const columns = [
    {
      name: "ID",
      selector: "txid",
      sortable: true,
    },
    {
      name: "Status",
      selector: "type",
      sortable: true,
      cell: row => <p style={{color: row.type === "SENT" ? "#00feae": "#9c49f0"}}>{row.type}</p>
    },
    {
      name: "Amount(PRCY)",
      selector: "amount",
      sortable: true,
    },
    {
      name: "Amount(USD)",
      selector: "amountInUsd",
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: "dateAndTime",
      sortable: true,
    },
  ];
  return (
    <DataTable
      columns={columns}
      customStyles={customStyles}
      data={data && data}
      pagination={true}
      noHeader={true}
      highlightOnHover={true}
      responsive={true}
      style={{ borderRadius: "0px" }}
      theme="dark"
    />
  );
};

export default withRouter(GridView);
