import React, { useContext, useEffect } from "react";
import sent from "../../assets/icons/sent.png";
import received from "../../assets/icons/received.png";
import "./Transaction.css";
import Context from "../../store/Store";
const PrcyUtils = require("prcyjs").Utils;
const config = require("prcyjs").Constants;

const Transaction = (props) => {
  const [state] = useContext(Context);
  const convertIntoTime = (timestamp) => {
    const date_ob = new Date(timestamp * 1000);
    const year = date_ob.getFullYear();
    const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    const date = ("0" + date_ob.getDate()).slice(-2);
    const hours = ("0" + date_ob.getHours()).slice(-2);
    const minutes = ("0" + date_ob.getMinutes()).slice(-2);
    const seconds = ("0" + date_ob.getSeconds()).slice(-2);
    return (
      year +
      "-" +
      month +
      "-" +
      date +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  useEffect(() => {
    if (!state.wallet.transactionHistory) {
      props.history.push("/login");
    }
  }, [props.history, state.wallet.transactionHistory]);

  var goToExplorer = config.PRCYCHAIN === 'testnet' ? "https://testnet.prcycoin.com/tx/" : "https://explorer.prcycoin.com/tx/";

  return (
    <div className="transaction">
      <div className="transactionInnerDiv">
        {props.type === "Sent" ? (
          <div className="transactionSentIconDiv">
            <img className="sentIcon" src={sent} alt="img" />
          </div>
        ) : (
            <div className="transactionReceivedIconDiv">
              <img className="receiveIcon" src={received} alt="img" />
            </div>
          )}
        <div className="transactionContent">
          <div className="transactionStatus">
            <span className="txId"><a href={goToExplorer + props.txId} target="_blank" rel="noopener noreferrer">{props.txId && props.txId.substring(0, 6) + '...' + props.txId.substring(64 - 4)}</a></span>
            <span
              className={props.type === "Sent" ? "sendType" : "receiveType"}
            >
              {props.type}
            </span>
          </div>
          <div className="transactionIds">
            <div>
              <span className="amount">{PrcyUtils.balanceInPRCY(props.amount)}</span>
            </div>
            <div>
              <span className="amountInDollars">
                {(PrcyUtils.balanceInPRCY(props.amount) * state.prcyPrice).toFixed(4)} USD
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="transactionTime">
        <span className="amountInDollars">
          {convertIntoTime(props.timestamp)}
        </span>
      </div>
    </div >
  );
};

export default Transaction;
