import React, { useState, useContext, useEffect } from "react";
import Context from "../../store/Store";
import { withRouter } from "react-router-dom";
import loginImage from "../../assets/images/login.png";
import CreateWalletModal from "../../components/modals/CreateWalletModal";
import { warningMessage } from "../../constants/index";
import Alert from "../../components/alerts/Alert";
import warningAlert from "../../assets/icons/warningAlert.png";
import ReCAPTCHA from "react-google-recaptcha-hcaptcha";
import "./Login.css";
const bip39 = require("bip39");
const config = require("prcyjs").Constants;

const Login = (props) => {
  const [initialState, dispatch] = useContext(Context);
  const [newMnemonics, setNewMnemonics] = useState("");
  const [state, setState] = useState({
    mnemonics: "",
    bitcoinSeed: false,
    terms: false,
    errors: {
      mnemonics: "",
      terms: ""
    },
  });
  const [open, setOpen] = useState(false);
  const [captcha, showCaptcha] = useState(false);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    if (initialState.wallet.scanWalletTransactions) {
      window.location.reload();
    }
  }, [initialState.wallet.scanWalletTransactions]);

  const useDocumentTitle = (title) => {
    useEffect(() => {
      document.title = document.title + " - "+ title
    }, [title])
  }

  const warningClose = () => {
    setWarning(false);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: "CREATE_WALLET",
      payload: true,
    });
    dispatch({
      type: "LOGIN",
      payload: { mnemonics: newMnemonics, bitcoinSeed: false },
    });
    props.history.push("/");
  };

  const closeCreate = () => {
    setOpen(false);
  };

  const setInputField = (evt) => {
    setState({
      ...state,
      mnemonics: evt.target.value,
    });
    validate(evt.target.name, evt.target.value);
  };

  const setTermsError = (value) => {
    state.errors.terms = value ? "" : "Please check Terms and Conditions";
  };

  const setTerms = (evt) => {
    setState({
      ...state,
      terms: evt.target.checked,
    });
    setTermsError(evt.target.checked);
  };


  const createNewWallet = () => {
    if (state.terms === false) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          terms: "Please check Terms and Conditions"
        },
      });
    } else {
      showCaptcha(true);
    }
  };

  const onChange = (value) => {
    if (value) {
      showCaptcha(false);
      setWarning(true);
    }
  };

  const onClickGotItBtn = () => {
    setNewMnemonics(bip39.generateMnemonic(256));
    setOpen(true);
    setWarning(false);
  };

  const validate = (name, value) => {
    switch (name) {
      case "mnemonics":
        state.errors.mnemonics =
          value === ""
            ? `24 Word Seed Phrase is required`
            : !bip39.validateMnemonic(value.trim())
              ? "Please enter a valid 24 word seed phrase."
              : "";
        break;
      default:
        break;
    }
  };

  const submit = () => {
    let mnemonics;
    let terms;
    if (state.mnemonics === "" || state.terms === false || state.errors.mnemonics !== "") {
      mnemonics = state.mnemonics === "" ? `24 Word Seed Phrase is required` : state.errors.mnemonics;
      terms = state.terms ? "" : "Please check Terms and Conditions";
      setState({
        ...state,
        errors: {
          mnemonics,
          terms
        },
      });
    } else {
      dispatch({
        type: "LOGIN",
        payload: { mnemonics: state.mnemonics, bitcoinSeed: state.bitcoinSeed },
      });
      props.history.push("/");
    }
  };

  useDocumentTitle(config.PRCYCHAIN.charAt(0).toUpperCase() + config.PRCYCHAIN.slice(1));
  return (
    <>
      <div className="loginContainer">
        <div>
          <img className="sideImage" alt="img" src={loginImage} />
        </div>
        <div className="loginLeftPane">
          <div className="loginDiv">
            <h1 className="loginHeading">{config.PRCYCHAIN.charAt(0).toUpperCase() + config.PRCYCHAIN.slice(1)} Login</h1>
            <div className="enterMessage">
              <p>Enter your 24 word seed phrase</p>
            </div>
            <div>
              <textarea
                className={`loginInputField ${
                  state.errors.mnemonics !== "" ? "error-border" : null
                  }`}
                type="text"
                onBlur={setInputField}
                onChange={setInputField}
                name="mnemonics"
              />
              <div className="errors">{state.errors.mnemonics}</div>
            </div>
{/*            <div className="bitCoinCheckDiv">
              <input
                className="bitCoinCheckBox"
                type="checkbox"
                name="bitCoin"
                onChange={setBitCoin}
              />
              <p className="bitCoinCheckMessage">Use Bitcoin seed (Tick this box if you created a PRCY address with PRCY web wallets, mobile wallets or with SecuX. <b>Do Not</b> use this if your mnemonic is from a current PRCY Desktop Wallet!)</p>
                </div>*/}
            <div className="bitCoinCheckDiv">
              <input
                className="bitCoinCheckBox"
                type="checkbox"
                name="terms"
                onChange={setTerms}
              />
              <p className="bitCoinCheckMessage">I have read and accepted <a href="https://prcycoin.com/terms" target="_blank" rel="noopener noreferrer" className="termsText">Terms and Conditions</a></p>
            </div>
            <div className="errors">{state.errors.terms}</div>
            <div className="loginBtnDiv">
              <div className="dntHaveWalletDiv">
                <p>Don't have a wallet yet?</p>
              </div>
              <div className="dntHaveWalletLinkDiv">
                <button onClick={createNewWallet} className="dntHaveWalletLink">
                  Create New Wallet
                </button>
              </div>
              <div className="loginBtn">
                <button className="btn" onClick={submit}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert
        open={warning}
        handleClose={warningClose}
        warningMessage={warningMessage}
        title={"Warning!"}
        src={warningAlert}
        color={"rgba(238, 255, 0, 0.03)"}
        onClick={onClickGotItBtn}
      />
      <CreateWalletModal
        open={open}
        handleClose={handleClose}
        closeCreate={closeCreate}
        mnemonics={newMnemonics}
      />
      {captcha ? (
        <div className="fadeMe">
          <ReCAPTCHA
            provider="hcaptcha"
            sitekey={config.CAPTCHA_KEY}
            onChange={onChange}
            className="centered"
            type="image"
          />
        </div>
      ) : null}
    </>
  );
};

export default withRouter(Login);
